import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(
  theme =>
    createStyles({
      actionButton: {
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        background: "#181818",
        display: "flex",
        alignItems: "center",
        fontWeight: "900",
        textTransform: "uppercase",
        position: "relative",
        padding: "10px 14px 10px",
        fontSize: 14,
        transition: "400ms ease",
        MozTransition: "none",
        overflow: "hidden",
        color: "#e12425",
        outline: "none",
        border: "solid 1px #e12425",
        borderRadius: 40,
        marginTop: 20,
        width: "fit-content",
        "&::before": {
          width: 30,
          height: 150,
          background: "#181818",
          position: "absolute",
          right: "-30px",
          top: "-30px",
          left: "auto",
          content: "''",
          transition: "right 0.4s ease",
          transitionDelay: "0.15s",
          WebkitTransitionDelay: "0.15s",
          visibility: "hidden"
        },
        "&::after": {
          width: 20,
          height: 150,
          background: "#181818",
          position: "absolute",
          right: "-30px",
          top: "-30px",
          left: "auto",
          content: "''",
          transition: "right 0.4s ease",
          MozTransition: "none",
          visibility: "hidden"
        },
        "&:hover": {
          backgroundColor: "white",
          color: "#181818",
          transition: "0.4s ease",
          border: "solid 1px white"
        },
        "&:hover::before": {
          right: "110%",
          visibility: "visible"
        },
        "&:hover::after": {
          right: "110%",
          visibility: "visible"
        }
      }
    }),
  { name: "MuiLinkButton", index: 1 }
)

const LinkButton = ({ title, navigation }) => {
  const classes = useStyles()

  return (
    <Link className={classes.actionButton} to={navigation}>
      <span>{title}</span>
    </Link>
  )
}

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  navigation: PropTypes.string.isRequired
}

export default LinkButton
