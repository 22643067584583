import React from "react"
import { useStaticQuery } from "gatsby"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Container, Grid } from "@material-ui/core"
import sparks from "../videos/sparks.mp4"
import Img from "gatsby-image"
import NavigationButton from "./buttons/NavigationButton"
import LinkButton from "./buttons/LinkButton"

const useStyles = makeStyles(
  theme =>
    createStyles({
      bannerContainer: { position: "relative" },
      logoContainer: {
        padding: 50,
        "& h1": {
          color: "#e12425",
          fontSize: "3rem",
          [theme.breakpoints.down("sm")]: {
            fontSize: "2rem"
          }
        },
        "& h4": {
          fontSize: "1.6rem",
          [theme.breakpoints.down("sm")]: {
            fontSize: "1.3rem"
          }
        },
        "& h5": {
          color: "#e12425",
          fontSize: "1.3rem",
          [theme.breakpoints.down("sm")]: {
            fontSize: "1rem"
          }
        },
        "& > p, > span": {
          fontSize: "1.2rem"
        },
        "& p": {
          color: "white",
          textTransform: "uppercase",
          fontWeight: "700",
          textAlign: "start"
        },
        [theme.breakpoints.up("md")]: {
          "& section": {
            paddingLeft: 30
          }
        },
        [theme.breakpoints.down("md")]: {
          "& h1": {
            marginTop: 30
          },
          "& h2": {
            display: "none"
          },
          "& p": {
            textAlign: "center"
          },
          "& button": {
            margin: "10px auto"
          },
          "& a": {
            margin: "10px auto"
          },
          "& section": {
            padding: "0 30px 30px",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            background: "#0000008a"
          }
        }
      },
      videoContainer: {
        top: "0%",
        left: "0%",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "absolute",
        zIndex: "-1",
        "& video": {
          position: "absolute",
          zIndex: "0",
          width: "100%",
          height: "100vh",
          // minWidth: 1700,
          top: 0
        },
        [theme.breakpoints.down("md")]: {
          "& video": {
            height: "unset"
          }
        }
      }
    }),
  { name: "MuiBanner", index: 1 }
)

const Banner = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      announcement: file(
        relativePath: { eq: "KIT_2022_General_Social_Media_Slide.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.logoContainer}>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12}></Grid>
            <Img
              style={{ width: "100%", margin: "0 auto" }}
              fluid={data.announcement.childImageSharp.fluid}
              alt="logo"
            />
            {/* <Grid
              item
              xs={12}
              lg={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                "& button": {
                  marginTop: 0
                }
              }}
            >
              <section>
                <h1>Kumite Gaming is Back!</h1>
                <h2>
                  Kumite Gaming has returned with an upcoming Kumite in
                  Tennessee 2021 tournament
                </h2>
                <NavigationButton
                  title="Register at Smash.gg"
                  navigation={`https://smash.gg/kit2021`}
                />
                <p style={{ marginTop: 5 }}> Registration is now open!</p>
                <NavigationButton
                  title="View Hotels"
                  navigation={`https://reservations.arestravel.com/hotel/list/14884/i5842?poiDistance=30`}
                />
                <p style={{ marginTop: 5 }}>
                  {" "}
                  Click for a list of available hotel locations
                </p>
                <LinkButton
                  title="View Details"
                  navigation={"/events/tournament/kumite-in-tennessee-2021"}
                />
                <p style={{ marginTop: 5 }}>Click for more info</p>
              </section>
            </Grid> */}
          </Grid>
        </Container>
      </div>
      <div className={classes.videoContainer}>
        <video id="videoBG" autoPlay muted loop>
          <source src={sparks} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default Banner
