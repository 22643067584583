import React from "react"
import { graphql, StaticQuery } from "gatsby"
import moment from "moment"
import { Container, Grid } from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import NavigationButton from "../buttons/NavigationButton"
import Img from "gatsby-image"

const kumiteHeroImage = require("../../images/kumite-hero-image.jpg")

//     announcement: file(
//   relativePath: { eq: "KIT_2021_General_Social_Media_Slide_v2.jpg" }
//   ) {
//     childImageSharp {
//       fluid(maxWidth: 3000, quality: 100) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }

const useStyles = makeStyles(
  theme =>
    createStyles({
      eventImage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          width: "100%"
        }
      },
      eventInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      },
      location: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "white",
        marginBottom: 10,
        "& span": {
          color: "white"
        },
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
          "& span": {
            justifyContent: "center",
            textShadow: "2px 2px 8px #181818"
          }
        }
      },
      noEvents: {
        "& a": {
          color: "#e12425"
        },
        "& a > span:hover": {
          transition: "0.4s ease",
          color: "white"
        }
      },
      heroSection: {
        backgroundImage: `url(${kumiteHeroImage})`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "& h1": {
          fontSize: "2rem"
        },
        "& h2": {
          fontSize: "1.5rem"
        },
        "& > p, > span": {
          fontSize: "1.2rem"
        },
        "& p": {
          color: "white",
          textTransform: "uppercase",
          fontWeight: "700",
          textAlign: "start"
        },
        [theme.breakpoints.down("md")]: {
          "& h1": {
            fontSize: "1.5rem",
            marginTop: 30
          },
          "& h2": {
            fontSize: "1.2rem"
          },
          backgroundImage: `none`
        }
      },
      callToAction: {
        paddingTop: 80,
        paddingBottom: 60,
        width: "100%",
        height: "100%",
        backgroundImage:
          "linear-gradient(101deg, #000000ab 47%, #181818 calc(47% + 2px))",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        minHeight: 300
      }
    }),
  { name: "MuiMainEventBanner", index: 1 }
)

const UpcomingEvent = () => {
  const classes = useStyles()

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <div className="empty"></div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.eventImage}>
            <img
              alt="Event Banner"
              src="https://images.start.gg/images/tournament/458407/image-cd7cac305a23b79f1c9702b9769e5779.jpg?ehk=jvBBNll9uX0mekAG00HPEKDgR2Sh1CUzrnyciDs0pMc%3D&ehkOptimized=tg14%2Fvu%2B6DtbHUZgiGmhOExJhLi1fGWE%2FLxKXT0fOXc%3D"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.eventInfo}>
            <h2>Kumite in Tennessee 2022: The Finale</h2>
            <div className={classes.location}>
              <LocationOnIcon />
              <span>Lebanon, TN</span>
            </div>
            <p>
              {moment.unix(1666989000).format("dddd, MMMM Do YYYY")} -{" "}
              {moment.unix(1667185200).format("dddd, MMMM Do YYYY")}
            </p>
            <NavigationButton
              title="Register at Smash.gg"
              navigation={`https://smash.gg/tournament/kumite-in-tennessee-2022-the-finale`}
            />
            <NavigationButton
              title="View Hotels"
              navigation={`https://reservations.arestravel.com/hotel/list/14884/i5842?poiDistance=30`}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default function MainEventBanner() {
  const classes = useStyles()
  return (
    <section className={classes.heroSection}>
      <div className={classes.callToAction}>
        <UpcomingEvent />
      </div>
    </section>
  )
}
