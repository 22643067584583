import React from "react"
import SEO from "../components/SEO"
import Nav from "../components/navigation/Nav"
import Banner from "../components/Banner"
import MainEventBanner from "../components/events/MainEventBanner"
import FeaturedTwitter from "../components/twitter/featuredTwitter"
import { Container } from "@material-ui/core"
import "./index.css"

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO
        title="Home"
        description="Welcome to Kumite Gaming. We host two of the largest esports tournaments in the fighting game community: Kumite in Tennessee and Kumite in Texas."
        keywords="KIT, KITX, summer bash, game galaxy, tennessee, texas, fgc, tournament, fighing games, esports"
      />
      <header>
        <Nav pathname={location.pathname} />
        <Banner />
      </header>

      <main>
        <MainEventBanner />

        {/* <div style={{ background: "#d4d4d4", width: "100%" }}>
          <Container maxWidth="lg">
            <section>
              <Intro />
            </section>
          </Container>
        </div> */}

        {/* <div style={{ background: "#181818", width: "100%" }}>
          <Container maxWidth="lg">
            <section>
              <FloorLayout />
            </section>
          </Container>
        </div> */}

        <div style={{ background: "#181818", width: "100%" }}>
          <Container maxWidth="lg">
            {/* <section>
              <Participation />
            </section> */}
            <section>
              <FeaturedTwitter />
            </section>
          </Container>
        </div>
      </main>
      {/* <Footer /> */}
    </>
  )
}

export default IndexPage
